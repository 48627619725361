<script setup lang="ts">
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/solid'
import { useCounterStore } from '@/stores/counter'
import { NotificationItemType } from './types/NotificationItem';
import { onMounted } from 'vue';

const store = useCounterStore()


onMounted(() => {

  if ((import.meta.env.VITE_MODE || "").indexOf("Production") >= 0) {
    let host = window.location.host;
    host = host.replace("app.", "api.");
    store.setApiUrl(window.location.protocol + "//" + host + "/");
    console.log("apiURL", window.location.protocol + "//" + host + "/");
  } else {
    store.setApiUrl(String(import.meta.env.VITE_API_URL));
    console.log("apiURL", import.meta.env.VITE_API_URL);
  }
  store.setApiUrl("https://api.getwinner.app/");
});

</script>

<template>
  <div id="appDiv" class="h-dvh  w-full max-h-dvh relative">
    <RouterView />

    <Teleport to="body">
      <div class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-[100]" v-if="store.notifications && store.notifications.length > 0">
        <div class="w-full max-w-sm">
          <transition-group enter-active-class="ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0"
            move-class="transition duration-500">
            <div v-for="notification in store.notifications" :key="notification.id"
              class="w-full max-w-sm mt-4 overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 ">
              <div class="p-4">
                <div class="flex items-start">
                  <div class="shrink-0">
                    <CheckCircleIcon v-if="notification.type === NotificationItemType.Success" class="h-6 w-6 text-green-400" aria-hidden="true" />
                    <ExclamationCircleIcon v-if="notification.type === NotificationItemType.Error" class="h-6 w-6 text-red-400" aria-hidden="true" />
                    <ExclamationCircleIcon v-if="notification.type === NotificationItemType.Warning" class="h-6 w-6 text-orange-400" aria-hidden="true" />
                    <InformationCircleIcon v-if="notification.type === NotificationItemType.Info" class="h-6 w-6 text-blue-400" aria-hidden="true" />
                  </div>

                  <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-medium text-gray-900">
                      {{ notification.title }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ notification.text }}
                    </p>
                    <template v-if="notification.subItems && notification.subItems.length > 0">
                      <ul class="mt-2 list-disc list-inside">
                        <li class="text-sm  text-gray-500" v-for="subItem in notification.subItems">{{ subItem }}</li>
                      </ul>
                    </template>
                  </div>
                  <div class="flex ml-4 shrink-0">
                    <button class="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                      @click="store.closeNotification(notification.id)">
                      <span class="sr-only">Close</span>
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </Teleport>
  </div>
</template>
