export enum AccountTypeEnum {
  Dummy = 0,
  Cash = 1,
  CashForStocks = 2,
  InvestingForStocks = 3,
  CashForCoins = 4,
  InvestingForCoins = 5,
  InvestingForFund = 6,
  Other = 7,
  InvestingForCommodities = 8,
}

export interface ApiResult<T> {
  result: T;
  error: string;
}

export interface BaseRequest {}

export interface GetAccountsRequest extends BaseRequest {
  accountId: number | null;
}

export interface GetHangfireJobsRequest extends BaseRequest {}

export interface RunTaskRequest extends BaseRequest {
  taskName: string | null;
  listFunctions: boolean | null;
}

export interface TriggerRecurringJobRequest extends BaseRequest {
  id: string;
}

export interface TriggerScheduledJobRequest extends BaseRequest {
  id: string;
}

export interface ClearRecurringJobsRequest extends BaseRequest {}

export interface ClearScheduledJobsRequest extends BaseRequest {}

export interface FixAllBeforeAndAfterCountsRequest extends BaseRequest {}

export interface FixStockPositionAveragePriceRequest extends BaseRequest {}

export interface GetStockTickersRequest extends BaseRequest {}

export interface GetCommodityTickersRequest extends BaseRequest {}

export interface GetFundTickersRequest extends BaseRequest {}

export interface GetCoinTickersRequest extends BaseRequest {}

export interface GetStockPricesRequest extends BaseRequest {
  tickerIds: number[] | null;
}

export interface GetCommodityPricesRequest extends BaseRequest {}

export interface GetSubscribedTickersRequest extends BaseRequest {}

export interface GetFundPricesRequest extends BaseRequest {}

export interface GetCoinPricesRequest extends BaseRequest {}

export interface GetTagRelationsRequest extends BaseRequest {
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
}

export interface GetNotesRequest extends BaseRequest {
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
}

export interface CreateNewNoteRequest extends BaseRequest {
  noteContent: string | null;
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
  noteType: NoteTypeEnum;
}

export interface CreateNewTagRelationRequest extends BaseRequest {
  tagId: number;
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
}

export interface CreateNewTagRequest extends BaseRequest {
  name: string | null;
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
  color: string;
}

export interface UpdateNoteRequest extends BaseRequest {
  noteId: number;
  noteContent: string | null;
  noteType: NoteTypeEnum | null;
}

export interface DeleteTagRelationRequest extends BaseRequest {
  tagRelationId: number;
}

export interface DeleteNoteRequest extends BaseRequest {
  noteId: number;
}

export interface CreateNewAccountRequest extends BaseRequest {
  accountType: AccountTypeEnum;
  currency: string;
  name: string;
  userChoice: CreateNewAccountSuggestionTypeEnum | null;
  description: string | null;
  amount: number | null;
}

export interface SearchStockTickerRequest extends BaseRequest {
  searchString: string | null;
  searchId: number | null;
}

export interface SearchTagRequest extends BaseRequest {
  getAll: boolean;
  searchString: string | null;
  searchId: number | null;
}

export interface SearchCommodityTickerRequest extends BaseRequest {
  searchString: string | null;
  searchId: number | null;
}

export interface SearchFundTickerRequest extends BaseRequest {
  searchString: string | null;
  searchId: number | null;
}

export interface SearchCoinTickerRequest extends BaseRequest {
  searchString: string | null;
  searchId: number | null;
}

export interface GetStockPositionsRequest extends BaseRequest {}

export interface GetCommodityPositionsRequest extends BaseRequest {}

export interface GetClosedStockPositionsRequest extends BaseRequest {}

export interface GetFundPositionsRequest extends BaseRequest {}

export interface GetCoinPositionsRequest extends BaseRequest {}

export interface GetSnapshotsRequest extends BaseRequest {}

export interface CreateSnapshotRequest extends BaseRequest {
  date: DateOnly;
  usersEmail: string;
}

export interface DeleteSnapshotRequest extends BaseRequest {
  snapshotId: number;
}

export interface GetStocksValueOnDayRequest extends BaseRequest {
  dayCount: number;
}

export interface GetCoinsValueOnDayRequest extends BaseRequest {
  dayCount: number;
}

export interface GetFundsValueOnDayRequest extends BaseRequest {
  dayCount: number;
}

export interface GetCommoditiesValueOnDayRequest extends BaseRequest {
  dayCount: number;
}

export interface GetCashesValueOnDayRequest extends BaseRequest {
  dayCount: number;
}

export interface GetAccountSnapshotsRequest extends BaseRequest {
  snapshotId: number;
}

export interface GetAccountSnapshotDetailsRequest extends BaseRequest {
  accountSnapshotId: number;
}

export interface GetAccountSnapshotDetailsResult {
  accountId: number;
  accountName: string;
  accountCurrency: string;
  accountType: AccountTypeEnum;
  coins: GetAccountSnapshotDetailsResultItem[];
  funds: GetAccountSnapshotDetailsResultItem[];
  stocks: GetAccountSnapshotDetailsResultItem[];
  commodities: GetAccountSnapshotDetailsResultItem[];
  transfers: GetTransfersResultItem[];
}

export interface GetAccountSnapshotDetailsResultItem {
  price: number | null;
  ticker: string;
  tickerId: number;
  count: number;
  dateOfPrice: DateOnly | null;
  id: number;
  error: string | null;
}

export interface GetAccountSnapshotsResult {
  items: GetAccountSnapshotsResultItem[];
  snapshotDate: DateOnly;
}

export interface GetAccountSnapshotsResultItem {
  id: number;
  amount: number;
  amountInUsd: number;
  error: string | null;
  accountId: number;
  accountName: string;
  accountCurrency: string;
  accountType: AccountTypeEnum;
}

export interface GetStocksValueOnDayResult {
  total: number;
  date: DateOnly;
  items: GetStocksValueOnDayResultItem[];
}

export interface GetStocksValueOnDayResultItem {
  accountId: number;
  accountName: string;
  amountInUsd: number;
}

export interface GetCoinsValueOnDayResult {
  total: number;
  date: DateOnly;
  items: GetCoinsValueOnDayResultItem[];
}

export interface GetCoinsValueOnDayResultItem {
  accountId: number;
  accountName: string;
  amountInUsd: number;
}

export interface GetFundsValueOnDayResult {
  total: number;
  date: DateOnly;
  items: GetFundsValueOnDayResultItem[];
}

export interface GetCommoditiesValueOnDayResult {
  total: number;
  date: DateOnly;
  items: GetCommoditiesValueOnDayResultItem[];
}

export interface GetCashesValueOnDayResult {
  total: number;
  date: DateOnly;
  items: GetCashesValueOnDayResultItem[];
}

export interface GetFundsValueOnDayResultItem {
  accountId: number;
  accountName: string;
  amountInUsd: number;
}

export interface GetCommoditiesValueOnDayResultItem {
  accountId: number;
  accountName: string;
  amountInUsd: number;
}

export interface GetCashesValueOnDayResultItem {
  accountId: number;
  accountName: string;
  amountInUsd: number;
}

export interface GetSnapshotsResult {
  items: GetSnapshotsResultItem[];
}

export interface CreateSnapshotResult {}

export interface DeleteSnapshotResult {
  changeCount: number;
}

export interface GetSnapshotsResultItem {
  id: number;
  amount: number;
  amountInUsd: number;
  createdDate: string;
  currency: string;
  error: string | null;
  snapshotDate: DateOnly;
  userId: number;
  stocksSum: number | null;
}

export interface GetStockPositionsResult {
  items: GetStockPositionsResultItem[];
}

export interface GetStockPositionsResultItem {
  id: number;
  accountId: number;
  stockTickerId: number;
  count: number;
  averagePrice: number;
  averagePriceInUsd: number;
}

export interface GetCommodityPositionsResult {
  items: GetCommodityPositionsResultItem[];
}

export interface GetCommodityPositionsResultItem {
  id: number;
  accountId: number;
  commodityTickerId: number;
  count: number;
  averagePrice: number;
  averagePriceInUsd: number;
}

export interface GetClosedStockPositionsResult {
  items: GetClosedStockPositionsResultItem[];
}

export interface GetClosedStockPositionsResultItem {
  id: number;
  accountId: number;
  stockTickerId: number;
  count: number;
  averagePrice: number;
  averagePriceInUsd: number;
  accountName: string;
  stockTickerTicker: string;
  stockTickerName: string | null;
  firstTransactionDate: string | null;
  lastTransactionDate: string | null;
  totalCost: number;
  totalIncome: number;
  transactions: GetClosedStockPositionsResultTransaction[];
}

export interface GetFundPositionsResult {
  items: GetFundPositionsResultItem[];
}

export interface GetFundPositionsResultItem {
  id: number;
  accountId: number;
  fundTickerId: number;
  count: number;
  averagePrice: number;
  averagePriceInUsd: number;
}

export interface GetCoinPositionsResult {
  items: GetCoinPositionsResultItem[];
}

export interface GetCoinPositionsResultItem {
  id: number;
  accountId: number;
  coinTickerId: number;
  count: number;
  averagePrice: number;
  averagePriceInUsd: number;
}

export interface DeleteTagRelationResult {
  deletedTagRelationId: number;
}

export interface DeleteNoteResult {
  deletedNoteId: number;
}

export interface CreateNewNoteResult {
  item: GetNotesResultItem;
}

export interface CreateNewTagRelationResult {
  item: GetTagsResultItem;
}

export interface CreateNewTagResult {
  item: GetTagsResultItem;
}

export interface UpdateNoteResult {
  item: GetNotesResultItem;
}

export interface GetTagRelationsResult {
  tags: GetTagsResultItem[];
}

export interface GetNotesResult {
  notes: GetNotesResultItem[];
}

export interface GetNotesResultItem {
  createdDate: string;
  id: number;
  noteContent: string;
  noteType: NoteTypeEnum;
  stockPositionId: number | null;
  coinPositionId: number | null;
  fundPositionId: number | null;
  commodityPositionId: number | null;
  priceOnDate: number | null;
}

export interface GetTagsResultItem {
  tagRelationId: number;
  tagId: number;
  tagColor: string;
  tagName: string;
  stockPositionId: number | null;
  fundPositionId: number | null;
  coinPositionId: number | null;
  commodityPositionId: number | null;
}

export interface GetSubscribedTickersResult {
  subscribedTickers: string[];
}

export interface GetStockPricesResult {
  items: GetStockPricesResultItem[];
}

export interface GetStockPricesResultItem {
  tickerId: number;
  ticker: string;
  lastClosePrice: number | null;
  lastCloseDate: DateOnly | null;
  realTimePrice: number | null;
  lastClosePriceSource: string;
}

export interface GetCommodityPricesResult {
  items: GetCommodityPricesResultItem[];
}

export interface GetCommodityPricesResultItem {
  tickerId: number;
  ticker: string;
  lastClosePrice: number | null;
  lastCloseDate: DateOnly | null;
  realTimePrice: number | null;
  lastClosePriceSource: string;
}

export interface GetFundPricesResult {
  items: GetFundPricesResultItem[];
}

export interface GetFundPricesResultItem {
  tickerId: number;
  ticker: string;
  lastClosePrice: number | null;
  lastCloseDate: DateOnly | null;
  realTimePrice: number | null;
  lastClosePriceSource: string;
}

export interface GetCoinPricesResult {
  items: GetCoinPricesResultItem[];
}

export interface GetCoinPricesResultItem {
  tickerId: number;
  ticker: string;
  lastClosePrice: number | null;
  lastCloseDate: DateOnly | null;
  realTimePrice: number | null;
  lastClosePriceSource: string;
}

export interface GetStockTickersResult {
  items: GetStockTickersResultItem[];
}

export interface GetStockTickersResultItem {
  id: number;
  ticker: string;
  name: string | null;
  typeOfEquity: string;
  isTheMarketOpen: boolean | null;
}

export interface GetCommodityTickersResult {
  items: GetCommodityTickersResultItem[];
}

export interface GetCommodityTickersResultItem {
  id: number;
  ticker: string;
  name: string | null;
  isTheMarketOpen: boolean | null;
}

export interface GetFundTickersResult {
  items: GetFundTickersResultItem[];
}

export interface GetFundTickersResultItem {
  id: number;
  ticker: string;
  name: string | null;
  isTheMarketOpen: boolean | null;
}

export interface GetCoinTickersResult {
  items: GetCoinTickersResultItem[];
}

export interface GetCoinTickersResultItem {
  id: number;
  ticker: string;
  name: string | null;
}

export interface SearchTagResult {
  items: SearchTagResultItem[];
}

export interface SearchStockTickerResult {
  items: SearchStockTickerResultItem[];
}

export interface SearchTagResultItem {
  id: number;
  name: string;
}

export interface SearchStockTickerResultItem {
  id: number;
  ticker: string;
  stockMarketId: number;
  stockMarketName: string;
  stockMarketShortName: string;
  name: string | null;
  typeOfEquity: string;
}

export interface SearchCommodityTickerResult {
  items: SearchCommodityTickerResultItem[];
}

export interface SearchCommodityTickerResultItem {
  id: number;
  ticker: string;
  name: string | null;
}

export interface SearchFundTickerResult {
  items: SearchFundTickerResultItem[];
}

export interface SearchFundTickerResultItem {
  id: number;
  ticker: string;
  fundMarketId: number;
  fundMarketName: string;
  fundMarketShortName: string;
  name: string | null;
  typeOfEquity: string;
}

export interface SearchCoinTickerResult {
  items: SearchCoinTickerResultItem[];
}

export interface SearchCoinTickerResultItem {
  id: number;
  ticker: string;
  name: string | null;
}

export interface GetStockTransactionsRequest extends BaseRequest {}

export interface GetCommodityTransactionsRequest extends BaseRequest {}

export interface GetFundTransactionsRequest extends BaseRequest {}

export interface GetCoinTransactionsRequest extends BaseRequest {}

export interface GetTransfersRequest extends BaseRequest {}

export interface DeleteStockTransactionRequest extends BaseRequest {
  id: number;
  deletedDescription: string | null;
}

export interface DeleteCommodityTransactionRequest extends BaseRequest {
  id: number;
  deletedDescription: string | null;
}

export interface DeleteFundTransactionRequest extends BaseRequest {
  id: number;
  deletedDescription: string | null;
}

export interface DeleteCoinTransactionRequest extends BaseRequest {
  id: number;
  deletedDescription: string | null;
}

export interface DeleteTransferRequest extends BaseRequest {
  id: number;
  deletedDescription: string | null;
}

export interface UpdateTransferRequest extends BaseRequest {
  id: number;
  createNewTransferRequest: CreateNewTransferRequest;
}

export interface UpdateStockTransactionRequest extends BaseRequest {
  id: number;
  createNewStockTransactionRequest: CreateNewStockTransactionRequest;
}

export interface CreateNewStockTransactionRequest extends BaseRequest {
  investingAccountId: number;
  buyOrSell: string;
  stockPrice: number;
  count: number;
  commission: number | null;
  stockTickerId: number;
  transactionDate: string;
  description: string | null;
  dontDeductFromCashAccount: boolean | null;
}

export interface UpdateCommodityTransactionRequest extends BaseRequest {
  id: number;
  createNewCommodityTransactionRequest: CreateNewCommodityTransactionRequest;
}

export interface CreateNewCommodityTransactionRequest extends BaseRequest {
  investingAccountId: number;
  moneySourceAccountId: number | null;
  buyOrSell: string;
  commodityPrice: number;
  count: number;
  commission: number | null;
  commodityTickerId: number;
  transactionDate: string;
  description: string | null;
}

export interface UpdateFundTransactionRequest extends BaseRequest {
  id: number;
  createNewFundTransactionRequest: CreateNewFundTransactionRequest;
}

export interface CreateNewFundTransactionRequest extends BaseRequest {
  investingAccountId: number;
  moneySourceAccountId: number | null;
  buyOrSell: string;
  fundPrice: number;
  count: number;
  commission: number | null;
  fundTickerId: number;
  transactionDate: string;
  description: string | null;
}

export interface UpdateCoinTransactionRequest extends BaseRequest {
  id: number;
  createNewCoinTransactionRequest: CreateNewCoinTransactionRequest;
}

export interface CreateNewCoinTransactionRequest extends BaseRequest {
  investingAccountId: number;
  buyOrSell: string;
  coinPrice: number;
  count: number;
  commission: number | null;
  coinTickerId: number;
  transactionDate: string;
  description: string | null;
  dontDeductFromCashAccount: boolean | null;
}

export interface CreateNewTransferRequest extends BaseRequest {
  fromAccountId: number | null;
  toAccountId: number | null;
  amount: number;
  commission: number | null;
  transactionDate: string;
  description: string | null;
  toAmount: number | null;
  rate: number | null;
  transferType: TransferTypeEnum;
}

export interface GetSellableStockRequest extends BaseRequest {
  stockTickerId: number;
  accountId: number;
}

export interface GetSellableCommodityRequest extends BaseRequest {
  commodityTickerId: number;
  accountId: number;
}

export interface GetSellableFundRequest extends BaseRequest {
  fundTickerId: number;
  accountId: number;
}

export interface GetSellableCoinRequest extends BaseRequest {
  coinTickerId: number;
  accountId: number;
}

export interface UpdateAccountRequest extends BaseRequest {
  id: number;
  currency: string;
  name: string;
  description: string | null;
  amount: number | null;
  amountDescription: string | null;
}

export interface SignUpRequest extends BaseRequest {
  fullname: string | null;
  eMail: string | null;
  password: string | null;
  googleAuthCode: string | null;
}

export interface SignInRequest extends BaseRequest {
  eMail: string | null;
  password: string | null;
  googleAuthCode: string | null;
}

export interface TriggerRecurringJobResult {
  result: string;
}

export interface TriggerScheduledJobResult {
  result: boolean;
}

export interface ClearRecurringJobsResult {}

export interface ClearScheduledJobsResult {}

export interface FixAllBeforeAndAfterCountsResult {
  log: string;
}

export interface FixStockPositionAveragePriceResult {
  log: string[];
}

export interface RunTaskResult {
  functions: string[];
  operationLog: string;
}

export interface GetHangfireJobsResult {
  recurringJobs: GetHangfireJobsResultRecurringJob[];
  scheduledJobs: GetHangfireJobsResultScheduledJob[];
}

export interface GetHangfireJobsResultRecurringJob {
  id: string;
  jobName: string;
  methodName: string;
  createdAt: string | null;
  cron: string;
  error: string;
  lastExecution: string | null;
  lastJobState: string;
  loadException: string;
  nextExecution: string | null;
  queue: string;
  removed: boolean;
  timeZoneId: string;
}

export interface GetHangfireJobsResultScheduledJob {
  enqueueAt: string;
  inScheduledState: boolean;
  loadException: string | null;
  scheduledAt: string | null;
  methodName: string;
  typeName: string;
  id: string;
}

export interface GetAccountsResult {
  items: GetAccountsResultItem[];
}

export interface GetSellableStockResult {
  sellableCount: number | null;
}

export interface GetSellableCommodityResult {
  sellableCount: number | null;
}

export interface GetSellableFundResult {
  sellableCount: number | null;
}

export interface GetSellableCoinResult {
  sellableCount: number | null;
}

export interface GetAccountsResultItem {
  id: number;
  amount: number | null;
  currency: string;
  name: string;
  description: string | null;
  accountType: AccountTypeEnum;
  cashAccountId: number | null;
  investingAccountId: number | null;
}

export interface CreateNewAccountResult {
  suggestToUser: CreateNewAccountSuggestionTypeEnum | null;
  createdAccountId: number | null;
  createdRelatedAccountId: number | null;
}

export interface UpdateAccountResult {
  dummy: boolean;
}

export interface GetTransfersResult {
  items: GetTransfersResultItem[];
}

export interface GetStockTransactionsResult {
  items: GetStockTransactionsResultItem[];
}

export interface GetClosedStockPositionsResultTransaction {
  amount: number;
  amountInUsd: number;
  averagePrice: number;
  buyOrSell: string;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  stockPositionId: number;
  stockTickerId: number;
  toAccountId: number;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  count: number;
  stockTicker_Name: string | null;
  stockTicker_Ticker: string;
  stockPrice: number;
  afterAveragePrice: number | null;
  countAfterTransaction: number;
  countBeforeTransaction: number;
}

export interface GetStockTransactionsResultItem {
  amount: number;
  amountInUsd: number;
  averagePrice: number;
  buyOrSell: string;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  stockPositionId: number;
  stockTickerId: number;
  toAccountId: number;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  count: number;
  stockTicker_Name: string | null;
  stockTicker_Ticker: string;
  stockPrice: number;
  countAfterTransaction: number;
  countBeforeTransaction: number;
}

export interface GetCommodityTransactionsResult {
  items: GetCommodityTransactionsResultItem[];
}

export interface GetCommodityTransactionsResultItem {
  amount: number;
  amountInUsd: number;
  averagePrice: number;
  buyOrSell: string;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  commodityPositionId: number;
  commodityTickerId: number;
  toAccountId: number;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  count: number;
  commodityTicker_Name: string | null;
  commodityTicker_Ticker: string;
  commodityPrice: number;
}

export interface GetFundTransactionsResult {
  items: GetFundTransactionsResultItem[];
}

export interface GetFundTransactionsResultItem {
  amount: number;
  amountInUsd: number;
  averagePrice: number;
  buyOrSell: string;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  fundPositionId: number;
  fundTickerId: number;
  toAccountId: number;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  count: number;
  fundTicker_Name: string | null;
  fundTicker_Ticker: string;
  fundPrice: number;
}

export interface GetCoinTransactionsResult {
  items: GetCoinTransactionsResultItem[];
}

export interface GetCoinTransactionsResultItem {
  amount: number;
  amountInUsd: number;
  averagePrice: number;
  buyOrSell: string;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  coinPositionId: number;
  coinTickerId: number;
  toAccountId: number;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  count: number;
  coinTicker_Name: string | null;
  coinTicker_Ticker: string;
  coinPrice: number;
}

export interface GetTransfersResultItem {
  amount: number;
  amountInUsd: number;
  commission: number | null;
  createdDate: string;
  date: string;
  description: string | null;
  exchangeRate: number | null;
  fromAccountId: number | null;
  id: number;
  toAccountId: number | null;
  fromAccount_AccountType: AccountTypeEnum | null;
  fromAccount_Currency: string | null;
  fromAccount_Name: string | null;
  toAccount_AccountType: AccountTypeEnum | null;
  toAccount_Currency: string | null;
  toAccount_Name: string | null;
  beforeAmountOfFromAccount: number | null;
  beforeAmountOfFromAccountInUsd: number | null;
  beforeAmountOfToAccount: number | null;
  beforeAmountOfToAccountInUsd: number | null;
  afterAmountOfFromAccount: number | null;
  afterAmountOfFromAccountInUsd: number | null;
  afterAmountOfToAccount: number | null;
  afterAmountOfToAccountInUsd: number | null;
  plusToAccountExchangeRate: number | null;
  plusToAccountAmount: number | null;
  minusFromAccountAmount: number | null;
  transferType: TransferTypeEnum;
}

export interface UpdateStockTransactionResult {
  cashAccountId: number;
}

export interface UpdateCommodityTransactionResult {
  cashAccountId: number | null;
}

export interface UpdateFundTransactionResult {
  cashAccountId: number | null;
}

export interface UpdateCoinTransactionResult {
  cashAccountId: number;
}

export interface UpdateTransferResult {
  accountIdsToUpdateOne: number[] | null;
}

export interface CreateNewStockTransactionResult {
  cashAccountId: number;
}

export interface CreateNewCommodityTransactionResult {
  cashAccountId: number | null;
}

export interface CreateNewFundTransactionResult {
  cashAccountId: number | null;
}

export interface CreateNewCoinTransactionResult {
  cashAccountId: number;
}

export interface CreateNewTransferResult {
  accountIdsToUpdateOne: number[] | null;
}

export interface DeleteStockTransactionResult {
  cashAccountId: number;
}

export interface DeleteCommodityTransactionResult {
  cashAccountId: number;
}

export interface DeleteFundTransactionResult {
  cashAccountId: number;
}

export interface DeleteCoinTransactionResult {
  cashAccountId: number;
}

export interface DeleteTransferResult {
  cashAccountId: number;
}

export interface SignUpResult {
  userId: number;
  jwtToken: string;
  eMail: string;
  displayName: string;
  eMailVerified: boolean | null;
  hasAnyAccounts: boolean | null;
}

export interface SignInResult {
  userId: number;
  jwtToken: string;
  eMail: string;
  displayName: string;
  eMailVerified: boolean | null;
  hasAnyAccounts: boolean | null;
}

export enum CreateNewAccountSuggestionTypeEnum {
  OpenNewInvestingForStocksAccount,
  OpenNewCashForStocksAccount,
  OpenNewInvestingForCoinsAccount,
  OpenNewCashForCoinsAccount,
}

export interface CurrenyItem {
  name: string;
  code: string;
  symbol: string;
}

export interface EnumItem {
  key: number;
  name: string;
  description: string | null;
  longDescription: string | null;
}

export interface ExchangeRate {
  symbol: string;
  price: number;
  date: string;
}

export enum NoteTypeEnum {
  StandartNote = 0,
  TaskToDo = 1,
  TaskCompleted = 2,
}

export enum TransferTypeEnum {
  FirstInit,
  AccountFix,
  Expense,
  Income,
  BuySomething,
  SellSomething,
  Transfer,
  ForeignExchange,
  DeleteBuySomething,
  DeleteSellSomething,
}

export interface UpdateStockPriceCommand {
  id: number;
  ticker: string;
  price: number;
  priceDate: string;
  source: string;
}

export interface UpdateCoinPriceCommand {
  id: number;
  ticker: string;
  price: number;
  priceDate: string;
  source: string;
}

export interface UpdateFundPriceCommand {
  id: number;
  ticker: string;
  price: number;
  priceDate: string;
  source: string;
}
